import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Contain from '../styled/Contain'
import { H3 } from '../styled/Heading'

const Message = () => {
  const data = useStaticQuery(graphql`
    query MessageQuery {
      allAirtable(filter: { table: { eq: "Mededeling" } }) {
        edges {
          node {
            data {
              Titel
              Tekst
              Zichtbaar
            }
          }
        }
      }
    }
  `)

  // Get the first item of all messages (only one message allowed)
  const message = data.allAirtable.edges[0]

  const { Titel, Tekst, Zichtbaar } = message.node.data

  return (
    <>
      {Zichtbaar && (
        <MessageWrapper as="aside">
          <H3 as="h2">{Titel.length > 0 ? Titel : 'Mededeling'}</H3>
          <p>{Tekst}</p>
        </MessageWrapper>
      )}
    </>
  )
}

export default Message

const MessageWrapper = styled(Contain)`
  margin-top: 80px;
  padding: 48px ${props => props.theme.layout.contain.containPadding};
  background-color: rgba(23, 66, 91, 0.05);

  h2 {
    margin-bottom: 24px;
  }

  @media (min-width: ${props => props.theme.layout.breakpoints.large}) {
    margin-top: 96px;
    margin-bottom: -80px;
    padding: 64px;
  }
`
