import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Panorama from '../components/Panorama'
import Message from '../components/Message'
import Banner from '../components/Banner'
import Testimonial from '../components/Testimonial'
import Logobar from '../components/Logobar'
import { formatDate, formatPrice, formatTimeRange } from '../utils/helpers'
import bannerImage from '../images/proefles.jpg'
import bannerImage2 from '../images/lespartner.jpg'

export default function IndexPage({ data }) {
  // 1. Get upcoming event which should be visible on the homepage
  const event = data.allAirtable.edges[0].node.data

  // 2. Destructure data
  const {
    // eslint-disable-next-line camelcase
    Startdatum_en_tijd,
    // eslint-disable-next-line camelcase
    Einddatum_en_tijd,
    Evenementnaam,
    Foto,
    Locatie,
    Prijs,
    // eslint-disable-next-line camelcase
    Video_url,
  } = event

  const image = Foto?.localFiles[0]?.childImageSharp?.gatsbyImageData || false
  // eslint-disable-next-line camelcase
  const video = Video_url || false

  return (
    <Layout>
      <Panorama
        title={Evenementnaam}
        date={formatDate(Startdatum_en_tijd)}
        location={Locatie}
        time={formatTimeRange(Startdatum_en_tijd, Einddatum_en_tijd)}
        price={formatPrice(Prijs)}
        image={image}
        video={video}
        url="/agenda"
        buttonLabel="Bekijk agenda"
        primary
      />
      <Message />
      <Banner
        title={data.signup.frontmatter.title}
        html={data.signup.html}
        buttonLabel="Bekijk agenda"
        image={bannerImage}
      />
      <Testimonial title="Onze leerlingen over Cuartito Azul" reverse />
      <Banner
        title={data.partner.frontmatter.title}
        html={data.partner.html}
        image={bannerImage2}
      />
      <Logobar
        title="Cuartito Azul werkt o.a. samen met"
        text="De Doelen, de Rotterdamse Schouwburg, Podium Grounds/WMDC, de Parade, het Conservatorium/Codarts, de Rotterdamse Museumnacht, Stichting de Loodsen, de Dansnacht, Rooftop Cinema en verschillende zomerfestivals."
      />
    </Layout>
  )
}

export const Head = () => <SEO title="Home" />

export const query = graphql`
  query HomePage($currentDate: Date!) {
    allAirtable(
      filter: {
        table: { eq: "Agenda" }
        data: {
          Startdatum_en_tijd: { gte: $currentDate }
          Toon_op_homepage: { eq: true }
        }
      }
      sort: { fields: data___Startdatum_en_tijd, order: ASC }
      limit: 1
    ) {
      edges {
        node {
          data {
            Evenementnaam
            Startdatum_en_tijd
            Einddatum_en_tijd
            Prijs
            Locatie
            Foto {
              localFiles {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
            Video_url
            Toon_op_homepage
          }
        }
      }
    }
    signup: markdownRemark(frontmatter: { path: { eq: "signup" } }) {
      html
      frontmatter {
        path
        title
      }
    }
    partner: markdownRemark(frontmatter: { path: { eq: "partner" } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
    allAirtable: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            data: PropTypes.shape({
              Evenementnaam: PropTypes.string.isRequired,
              Startdatum_en_tijd: PropTypes.string.isRequired,
              Einddatum_en_tijd: PropTypes.string.isRequired,
              Prijs: PropTypes.number.isRequired,
              Locatie: PropTypes.string.isRequired,
              Foto: PropTypes.object,
              Video_url: PropTypes.string,
              Toon_op_homepage: PropTypes.bool,
            }).isRequired,
          }).isRequired,
        })
      ),
    }),
  }),
}
