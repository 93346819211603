import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { H2 } from '../styled/Heading'
import { Intro } from '../styled/Text'
import Contain from '../styled/Contain'

import deDoelen from '../images/logo-de-doelen.svg'
import erasmus from '../images/logo-erasmus-mc.svg'
import codarts from '../images/logo-codarts.svg'
import parade from '../images/logo-parade.svg'

const LOGOS = [
  {
    name: 'De Doelen',
    image: deDoelen,
    link: 'https://www.dedoelen.nl/',
  },
  {
    name: 'Codarts',
    image: codarts,
    link: 'https://www.codarts.nl/',
  },
  {
    name: 'Erasmus MC',
    image: erasmus,
    link: 'https://www.erasmusmc.nl/',
  },
  {
    name: 'Parade',
    image: parade,
    link: 'https://deparade.nl/',
  },
]

const Logobar = props => {
  const { title, text } = props

  return (
    <LogobarWrapper size="medium">
      <H2>{title}</H2>
      <Intro>{text}</Intro>
      <LogobarList>
        {LOGOS.map((logo, index) => (
          <li key={index}>
            <a href={logo.link} target="_blank" rel="noopener noreferrer">
              <img src={logo.image} alt={logo.name} />
            </a>
          </li>
        ))}
      </LogobarList>
    </LogobarWrapper>
  )
}

Logobar.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Logobar

const LogobarWrapper = styled(Contain)`
  margin-top: 80px;
  text-align: center;

  @media (min-width: ${props => props.theme.layout.breakpoints.large}) {
    margin-top: 160px;
  }
`

const LogobarList = styled.ul`
  display: grid;
  grid-column-gap: ${props => props.theme.gridColumnGap};
  grid-row-gap: 48px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 56px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 80px;
    }
  }

  @media (min-width: ${props => props.theme.layout.breakpoints.medium}) {
    grid-template-columns: repeat(4, 1fr);
  }
`
