import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { H2 } from '../styled/Heading'
import Contain from '../styled/Contain'
import img from '../images/quotes.svg'
import testimonialImage from '../images/placeholder-testimonial.png'
import pattern from '../images/pattern-tertiary.svg'

const Testimonial = (props) => {
  const data = useStaticQuery(graphql`
    query TestimonialQuery {
      allAirtable(filter: { table: { eq: "Testimonial" } }) {
        edges {
          node {
            data {
              Naam
              Tekst
              Afbeelding {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 600
                      quality: 100
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { title } = props

  // Array of all testimonials
  const testimonials = data.allAirtable.edges

  // Get random testimonial (single item)
  const testimonial =
    testimonials[Math.floor(Math.random() * testimonials.length)]

  const { Naam, Tekst, Afbeelding } = testimonial.node.data

  return (
    <TestimonialWrapper>
      <TestimonialImage>
        {Afbeelding ? (
          <GatsbyImage
            image={Afbeelding.localFiles[0].childImageSharp.gatsbyImageData}
            alt={Naam}
          />
        ) : (
          <img src={testimonialImage} alt={Naam} />
        )}
      </TestimonialImage>
      <TestimonialContent>
        <H2>{title}</H2>
        <blockquote>
          <p>{Tekst}</p>
        </blockquote>
        <figcaption>{Naam}</figcaption>
      </TestimonialContent>
    </TestimonialWrapper>
  )
}

Testimonial.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Testimonial

const TestimonialWrapper = styled(Contain)`
  margin-top: 80px;

  blockquote {
    position: relative;
    margin: 40px 0 0 0;

    &::before {
      position: absolute;
      top: -24px;
      right: 0;
      height: 87px;
      width: 130px;
      content: '';
      z-index: -1;
      background-image: url(${img});
    }
  }

  figcaption {
    margin-top: 40px;
    font-weight: ${(props) => props.theme.fontWeights.heading};

    &::before {
      height: 2px;
      width: 56px;
      content: '';
      display: block;
      margin-bottom: 16px;
      background-color: ${(props) => props.theme.colors.secondary};
    }
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    display: flex;
    margin-top: 160px;

    blockquote {
      &::before {
        position: absolute;
        top: -24px;
        right: auto;
        left: -40px;
      }
    }

    figcaption {
      margin-top: 72px;
    }
  }
`

const TestimonialImage = styled.div`
  position: relative;
  /* margin-top: 88px; */

  img {
    width: 100%;
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 150px;
    height: 150px;
    content: '';
    z-index: 1;
    transform: translate3d(-50%, 50%, 0);
    background-color: rgba(109, 203, 206, 0.2);
    background-image: url(${pattern});
    mix-blend-mode: multiply;
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    flex: 0 0 50%;
    align-self: flex-start;
    margin-top: 0;

    &::before {
      width: 300px;
      height: 300px;
    }
  }
`

const TestimonialContent = styled.div`
  margin-top: 32px;

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 64px;
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.wide}) {
    padding-left: 96px;
  }
`
